/*fonts*/
@mixin font-face($style-name, $file:$style-name, $weight:normal, $style:normal) {
    $filepath: "/font/" + $file;
    @font-face {
        font-family: quote($style-name);
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
        url($filepath + ".woff") format('woff'),
        url($filepath + ".ttf")  format('truetype'),
        url($filepath + ".svg#" + $style-name + "") format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}

@include font-face("Baloo 2", "Baloo2-Regular", 400, normal);
@include font-face("Baloo 2", "Baloo2-Medium", 500, normal);
@include font-face("Baloo 2", "Baloo2-SemiBold", 600, normal);
@include font-face("Baloo 2", "Baloo2-Bold", 700, normal);
@include font-face("Baloo 2", "Baloo2-ExtraBold", 800, normal);

@include font-face("Segoe UI", "SegoeUI-Regular", 400, normal);
@include font-face("Segoe UI", "SegoeUI-SemiBold", 600, normal);

//font: 400 1.6rem/120% var(--Baloo);
//font: 500 1.8rem/120% var(--Baloo);
//font: 600 2.4rem/120% var(--Baloo);
//
//font: 400 1.6rem/120% var(--SegoeUI)
//font: 400 1.6rem/160% var(--SegoeUI);
//font: 600 1.4rem/120% var(--SegoeUI);



:root {
    --color-white: #fff;
    --color-black: #000;
    --color-bg: #FAF3F3;
    --color-orange: #F79F1F;
    --color-light-orange: #FEF5E9;
    --color-beige: #FDE2BC;
    --color-light-white: #FAFBFB;
    --color-light-white1: #EAF5FB;
    --color-text-gray: #212121;
    --color-light-gray: #747D8C;
    --color-light-gray1: #A4B0BE;
    --color-light-gray2: #CED6E0;
    --color-brown:#817e7e;
    --color-blue:#2D98DA;
    --color-dark-blue: #1586CB;
    --color-light-blue:#D5EAF8;
    --color-light-blue2: #81C1E9;
    --color-light-blue3: #DAEEFC;
    --color-light-blue4: #ABD6F0;
    --color-light-blue5: #96CBEC;
    --color-track-scroll: #F5FAFD;
    --color-error-red: #F82C30;
    --color-error-red2: #FF5E57;
    --color-turquoise: #38ADA9;
    --color-bone: #f7f8f7;

    --blue-shadow: rgba(45, 152, 218, 0.14);
    --blue-shadow1: rgba(30, 94, 134, 0.20);
    --brown-shadow: rgba(247, 159, 31, 0.14);

    --Baloo: 'Baloo 2', sans-serif;
    --SegoeUI: 'Segoe UI', sans-serif;
}

*,*:before,
*:after {
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}
*,body,html {
    -webkit-text-size-adjust: none;
    -webkit-appearance: none;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
button{
    border:none;
}
mark{
    -webkit-text-fill-color: initial;
    text-fill-color: transparent;
    background: none;
}

.img{
    &.contain img{
        object-fit: contain;
    }
    & > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}
ul {
    list-style: none;
}
a{
    text-decoration: none;
    color: inherit;
    &:hover{
        text-decoration: none;
    }
}
h1{
    margin: 0;
}
html {
    font-size: 10px;
    scroll-behavior: smooth;
    @media only screen and (max-width: 1680px) {
        font-size: 8.75px;
    }
    @media only screen and (max-width: 1600px) {
        font-size: 8.33px;
    }
    @media only screen and (max-width: 1560px) {
        font-size: 8.125px;
    }

    @media only screen and (max-width: 1440px) {
        font-size: 7.5px;
    }

    @media only screen and (max-width: 1366px) {
        font-size: 7.11px;
    }

    @media only screen and ( max-width: 1280px){
        font-size: 6.66px;
    }

    @media only screen and (max-width: 1024px) {
        font-size: 5.33px;
    }

    @media only screen and (max-width: 768px) {
        font-size: 4px;
    }

    @media only screen and (max-width: 666px) {
        font-size: 10px;
    }
}

/*fonts*/
@font-face {
  font-family: "Baloo 2";
  src: url("/font/Baloo2-Regular.eot");
  src: url("/font/Baloo2-Regular.eot?#iefix") format("embedded-opentype"), url("/font/Baloo2-Regular.woff") format("woff"), url("/font/Baloo2-Regular.ttf") format("truetype"), url("/font/Baloo2-Regular.svg#Baloo 2") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Baloo 2";
  src: url("/font/Baloo2-Medium.eot");
  src: url("/font/Baloo2-Medium.eot?#iefix") format("embedded-opentype"), url("/font/Baloo2-Medium.woff") format("woff"), url("/font/Baloo2-Medium.ttf") format("truetype"), url("/font/Baloo2-Medium.svg#Baloo 2") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Baloo 2";
  src: url("/font/Baloo2-SemiBold.eot");
  src: url("/font/Baloo2-SemiBold.eot?#iefix") format("embedded-opentype"), url("/font/Baloo2-SemiBold.woff") format("woff"), url("/font/Baloo2-SemiBold.ttf") format("truetype"), url("/font/Baloo2-SemiBold.svg#Baloo 2") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Baloo 2";
  src: url("/font/Baloo2-Bold.eot");
  src: url("/font/Baloo2-Bold.eot?#iefix") format("embedded-opentype"), url("/font/Baloo2-Bold.woff") format("woff"), url("/font/Baloo2-Bold.ttf") format("truetype"), url("/font/Baloo2-Bold.svg#Baloo 2") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Baloo 2";
  src: url("/font/Baloo2-ExtraBold.eot");
  src: url("/font/Baloo2-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/font/Baloo2-ExtraBold.woff") format("woff"), url("/font/Baloo2-ExtraBold.ttf") format("truetype"), url("/font/Baloo2-ExtraBold.svg#Baloo 2") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/font/SegoeUI-Regular.eot");
  src: url("/font/SegoeUI-Regular.eot?#iefix") format("embedded-opentype"), url("/font/SegoeUI-Regular.woff") format("woff"), url("/font/SegoeUI-Regular.ttf") format("truetype"), url("/font/SegoeUI-Regular.svg#Segoe UI") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/font/SegoeUI-SemiBold.eot");
  src: url("/font/SegoeUI-SemiBold.eot?#iefix") format("embedded-opentype"), url("/font/SegoeUI-SemiBold.woff") format("woff"), url("/font/SegoeUI-SemiBold.ttf") format("truetype"), url("/font/SegoeUI-SemiBold.svg#Segoe UI") format("svg");
  font-weight: 600;
  font-style: normal;
}

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-bg: #FAF3F3;
  --color-orange: #F79F1F;
  --color-light-orange: #FEF5E9;
  --color-beige: #FDE2BC;
  --color-light-white: #FAFBFB;
  --color-light-white1: #EAF5FB;
  --color-text-gray: #212121;
  --color-light-gray: #747D8C;
  --color-light-gray1: #A4B0BE;
  --color-light-gray2: #CED6E0;
  --color-brown:#817e7e;
  --color-blue:#2D98DA;
  --color-dark-blue: #1586CB;
  --color-light-blue:#D5EAF8;
  --color-light-blue2: #81C1E9;
  --color-light-blue3: #DAEEFC;
  --color-light-blue4: #ABD6F0;
  --color-light-blue5: #96CBEC;
  --color-track-scroll: #F5FAFD;
  --color-error-red: #F82C30;
  --color-error-red2: #FF5E57;
  --color-turquoise: #38ADA9;
  --color-bone: #f7f8f7;
  --blue-shadow: rgba(45, 152, 218, 0.14);
  --blue-shadow1: rgba(30, 94, 134, 0.20);
  --brown-shadow: rgba(247, 159, 31, 0.14);
  --Baloo: 'Baloo 2', sans-serif;
  --SegoeUI: 'Segoe UI', sans-serif;
}

*, *:before,
*:after {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

*, body, html {
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  border: none;
}

mark {
  -webkit-text-fill-color: initial;
  text-fill-color: transparent;
  background: none;
}

.img.contain img {
  object-fit: contain;
}

.img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

h1 {
  margin: 0;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 1680px) {
  html {
    font-size: 8.75px;
  }
}

@media only screen and (max-width: 1600px) {
  html {
    font-size: 8.33px;
  }
}

@media only screen and (max-width: 1560px) {
  html {
    font-size: 8.125px;
  }
}

@media only screen and (max-width: 1440px) {
  html {
    font-size: 7.5px;
  }
}

@media only screen and (max-width: 1366px) {
  html {
    font-size: 7.11px;
  }
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 6.66px;
  }
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 5.33px;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 4px;
  }
}

@media only screen and (max-width: 666px) {
  html {
    font-size: 10px;
  }
}
